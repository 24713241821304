import React, {ChangeEvent, ReactElement, useEffect, useState} from "react";
import {
    faDownload,
    faEnvelope,
    faXmark
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {RequestType} from "../../../types/RequestType";
import {REST} from "../../../REST";
import {EmployeeType} from "../../../types/EmployeeType";
import {StatusPart} from "../../../component/part/StatusPart";
import {RequestBlock} from "../request_blocks/RequestBlock";
import {RequestBlockType} from "../../../types/RequestBlockType";
import {Utils} from "../../../utils/Utils";

export function RequestModal(props: any): ReactElement {
    const me: EmployeeType = JSON.parse(sessionStorage.getItem("me") ?? '{}');
    const readOnly: boolean = props.readOnly;
    const [visible, setVisible] = props.visibleState as [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    const [currentRequest, setCurrentRequest] = props.currentRequestState as [RequestType | null, React.Dispatch<React.SetStateAction<RequestType | null>>];
    const [subordinates, setSubordinates] = useState<EmployeeType[]>([]);
    const [blocks, setBlocks] = useState<RequestBlockType[]>([]);
    const [newBlock, setNewBlock] = useState<RequestBlockType | null>(null);
    const [isBlockSaved, setBlockSaved] = useState<boolean>(false);

    useEffect(() => {
        REST.getSubordinates().then(s => {
            setSubordinates(s);
        });
    }, []);

    useEffect(() => {
        setBlocks(currentRequest?.blocks ?? []);
    }, [currentRequest]);

    const hideMe = () => {
        setCurrentRequest(null);
        setVisible(false);
    }

    const statusRequest = (id: bigint, status: string) => {
        REST.setRequestStatus(id, status).then((r: RequestType) => {
            hideMe();
            //TODO: update only one record in table
        })
    }

    const changeFinalExecutor = (e: ChangeEvent<HTMLSelectElement>) => {
        const finalExecutorID = parseInt(e.target.value);
        const payload = {
            id: currentRequest?.id,
            feid: finalExecutorID
        }
        REST.changeFinalExecutor(payload);
    }

    /*const uploadDocument = (r: RequestType | undefined) => {
        let file: HTMLInputElement = document.getElementById("document") as HTMLInputElement;
        let fileType: HTMLInputElement = document.getElementById("doctype") as HTMLInputElement;
        if (file.files!.length === 0)
            return
        let formData = new FormData();

        formData.append("data", file.files!.item(0)!);
        formData.append("name", file.files!.item(0)!.name);
        formData.append("type", fileType.value);
        formData.append("rid", r!.id.toString());

        REST.uploadFile(formData).then((f: FileType) => {
            file.value = '';
            if (currentRequest === undefined)
                currentRequest.files = [f];
            else
                currentRequest.files?.push(f);
        });
        //TODO: add spinner while upload
        //TODO: add error button on fail
    };*/

    if (!visible) {
        return (<></>);
    }

    const existsBlockType = (type: number) => {
        return ((blocks.filter((b) => b.type === type).length ?? 0) > 0);
        /*if((blocks.length??0) > 0) {
            return (blocks.at((blocks.length) - 1)!.type) === type;
        }
        return false;*/
        //console.log(type + ":" + ret);
    }

    const addNewBlock = (type: number) => {
        setNewBlock({
            id: undefined,
            rid: currentRequest?.id!,
            type: type,
            result: null,
            violations: null,
            prohibited_objects: null,
            cargo: null,
            photo_ts: null,
            photo_holds: null,
            photos: null,
            ship_role: null,
            lading_bill: null,
            manifesto: null,
            inspection_report: null,
            sealing_act: null,
            formattedCreatedAt: null,
            formattedStartAt: null,
            formattedFinishAt: null
        });
        setBlockSaved(false);
    }

    return (
        <div className={"ModalBackground"}>
            <div className={"Modal"}>
                <div className={"ModalHeader"} style={{justifyContent: "space-between"}}>

                    <StatusPart status={currentRequest?.status}/>
                    {"Заявка №" + currentRequest?.id + " от " + currentRequest?.formattedDate}

                    <div onClick={() => hideMe()} style={{cursor: "pointer"}}>
                        <FontAwesomeIcon icon={faXmark}/>
                    </div>
                </div>

                <div className={"ModalBody"}>


                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Заказчик</span>
                        <div className="form-control"
                             id="customername">{currentRequest?.customer.name}</div>
                        <span className="input-group-text">Наименование судна</span>
                        <div className="form-control" id="otbname">{currentRequest?.otb}</div>
                        <span className="input-group-text">Флаг</span>
                        <div className="form-control" id="flag">{currentRequest?.flag.name}</div>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">ИМО номер</span>
                        <div className="form-control"
                             id="imonumber">{currentRequest?.imonumber.toString()}</div>
                        <span className="input-group-text">Регистровый номер</span>
                        <div className="form-control"
                             id="registrynumber">{currentRequest?.registrynumber}</div>
                        <span className="input-group-text">Реестровый номер</span>
                        <div className="form-control"
                             id="reestrnumber">{currentRequest?.reestrnumber}</div>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Контакты судна</span>
                        <div className="form-control" id="contact">{currentRequest?.contact}</div>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Приход в порт (причал)</span>
                        <div className="form-control" id="inport">{currentRequest?.inport}</div>
                        <span className="input-group-text">страна</span>
                        <div className="form-control"
                             id="incountry">{currentRequest?.incountry.name}</div>
                        <span className="input-group-text">из порта</span>
                        <div className="form-control" id="fromport">{currentRequest?.fromport}</div>
                        <span className="input-group-text">страна</span>
                        <div className="form-control"
                             id="fromcountry">{currentRequest?.fromcountry.name}</div>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Отход в порт</span>
                        <div className="form-control" id="outport">{currentRequest?.outport}</div>
                        <span className="input-group-text">страна</span>
                        <div className="form-control"
                             id="outcountry">{currentRequest?.outcountry.name}</div>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Цель захода</span>
                        <div className="form-control" id="target">{currentRequest?.target}</div>
                        <span className="input-group-text">место</span>
                        <div className="form-control" id="place">{currentRequest?.place}</div>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Груз</span>
                        <div className="form-control" id="cargo">{currentRequest?.cargo}</div>
                        <span className="input-group-text">кол-во (т./куб.м)</span>
                        <div className="form-control" id="count">{currentRequest?.count}</div>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Ориентировочная дата и время прибытия</span>
                        <div className="form-control"
                             id="indatetime">{currentRequest?.formattedIndatetime}</div>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Ориентировочная дата начала и окончания погрузки</span>
                        <div className="form-control"
                             id="startloadtime">{currentRequest?.formattedStartloadtime}</div>
                        <span className="input-group-text">&nbsp;-&nbsp;</span>
                        <div className="form-control"
                             id="endloadtime">{currentRequest?.formattedEndloadtime}</div>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Адрес места погрузки</span>
                        <div className="form-control"
                             id="addressobject">{currentRequest?.addressobject}</div>
                        <span className="input-group-text">регион</span>
                        <div className="form-control"
                             id="addressobject">{currentRequest?.addressobjectregion.name}</div>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Наименование оператора</span>
                        <div className="form-control"
                             id="nameoperator">{currentRequest?.nameoperator}</div>
                        <span className="input-group-text">Контакты оператора</span>
                        <div className="form-control"
                             id="contactoperator">{currentRequest?.contactoperator}</div>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Cудовладелец</span>
                        <div className="form-control"
                             id="mastername">{currentRequest?.mastername}</div>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Контакты судовладельца</span>
                        <div className="form-control"
                             id="contactmaster">{currentRequest?.contactmaster}</div>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Грузоотправитель</span>
                        <div className="form-control" id="namecargo">{currentRequest?.namecargo}</div>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Контакты грузоотправителя</span>
                        <div className="form-control"
                             id="namecargo">{currentRequest?.contactcargo}</div>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Грузополучатель</span>
                        <div className="form-control" id="namecargo">{currentRequest?.nameconsignee}</div>
                    </div>
                    {Utils.hasRole("water_ptb_request")
                        ? <div className="input-group input-group-sm mb-1 mt-3">
                            <span className="input-group-text">Назначение</span>
                            {readOnly || subordinates?.length === 1 || currentRequest?.status.value === "REJECTED" || currentRequest?.status.value === "EXECUTED"
                                ? <div className="form-control"
                                       id="final-executor">{currentRequest?.finalExecutor.fio}</div>
                                : <select className="form-select" id="final-executor" name="finalexecutor"
                                          onChange={changeFinalExecutor}
                                          defaultValue={subordinates?.find((s) => s.id === currentRequest?.finalExecutor.id)?.id}>
                                    {subordinates?.map((s: EmployeeType) =>
                                        <option value={s.id} key={s.id} disabled={s.disabled}>{s.fio}</option>)
                                    }
                                </select>
                            }
                        </div>
                        : ""
                    }
                    <hr/>
                    {blocks.map((b, i) => <RequestBlock block={b} key={i} blocks={[blocks, setBlocks]}
                                                        saved={[isBlockSaved, setBlockSaved]}
                                                        requestNumber={currentRequest?.id}
                                                        readOnly={readOnly}/>)}
                    {me.cert !== null && currentRequest?.status.value === 'PROCESS'
                        ? <div style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                            {
                                existsBlockType(1)
                                    ? (existsBlockType(3)
                                            ? (existsBlockType(5)
                                                    ? ""
                                                    : (!isBlockSaved && newBlock !== null
                                                        ? <RequestBlock block={newBlock}
                                                                        blocks={[blocks, setBlocks]}
                                                                        saved={[isBlockSaved, setBlockSaved]}
                                                                        requestNumber={currentRequest?.id}/>
                                                        : (<div style={{
                                                            display: "flex",
                                                            justifyContent: "center"
                                                        }}>
                                                            <div className={"Button Primary"}
                                                                 onClick={() => addNewBlock(3)}>Добавить
                                                                отчет о досмотре
                                                            </div>
                                                            <div className={"Button Primary"}
                                                                 style={{marginLeft: "10px"}}
                                                                 onClick={() => addNewBlock(5)}>Добавить
                                                                отчет о результатах досмотра
                                                            </div>
                                                        </div>))
                                            )
                                            : (!isBlockSaved && newBlock !== null
                                                ? <RequestBlock block={newBlock}
                                                                blocks={[blocks, setBlocks]}
                                                                saved={[isBlockSaved, setBlockSaved]}
                                                                requestNumber={currentRequest?.id}/>
                                                : (<div style={{
                                                    display: "flex",
                                                    justifyContent: "center"
                                                }}>
                                                    <div className={"Button Primary"}
                                                         onClick={() => addNewBlock(3)}>Добавить
                                                        отчет о досмотре
                                                    </div>
                                                    <div className={"Button Primary"}
                                                         style={{marginLeft: "10px"}}
                                                         onClick={() => addNewBlock(5)}>Добавить
                                                        отчет о результатах досмотра
                                                    </div>
                                                </div>))

                                    )
                                    : (!isBlockSaved && newBlock !== null
                                            ? <RequestBlock block={newBlock}
                                                            blocks={[blocks, setBlocks]}
                                                            saved={[isBlockSaved, setBlockSaved]}
                                                            requestNumber={currentRequest?.id}/>
                                            : <div className={"Button Primary"} onClick={() => addNewBlock(1)}>Добавить
                                                отчет о начале досмотра</div>
                                    )
                            }
                        </div>
                        : ""
                    }
                </div>

                <div className={"ModalFooter"}>
                    <div className="Button Primary"
                         onClick={() => REST.downloadReport(currentRequest, 'docx')}>
                        <FontAwesomeIcon icon={faDownload}/>&nbsp;Скачать
                    </div>
                    {currentRequest?.status.value === "REJECTED"
                        ? ""
                        : <div className="Button Default Disabled" style={{marginLeft: "10px"}}
                               onClick={() => alert('Будет произведена отправка в ЕГИС ОТБ')}>
                        <FontAwesomeIcon icon={faEnvelope}/>&nbsp;Отправить в ЕГИС ОТБ
                        </div>
                    }
                    {!readOnly && currentRequest?.status.value === "NEW" ?
                        <div className="Button Primary" style={{marginLeft: "10px"}}
                             onClick={() => statusRequest(currentRequest?.id!, 'REVIEW')}>В
                            рассмотрение</div>
                        : ""}
                    {!readOnly && currentRequest?.status.value === "REVIEW" ?
                        <div className="Button Warning" style={{marginLeft: "10px"}}
                             onClick={() => statusRequest(currentRequest?.id!, 'PROCESS')}>Начать досмотр
                        </div>
                        : ""}
                    {!readOnly && currentRequest?.status.value === "PROCESS" && me.login === me.company.inn.toString() ?
                        <div className="Button Success" style={{marginLeft: "10px"}}
                             onClick={() => statusRequest(currentRequest?.id!, 'EXECUTED')}>Исполнить
                        </div>
                        : ""}
                    {!readOnly && (currentRequest?.status.value === "NEW" || currentRequest?.status.value === "REVIEW" || currentRequest?.status.value === "PROCESS") && me.login === me.company.inn.toString() ?
                        <div className="Button Danger" style={{marginLeft: "10px"}}
                             onClick={() => statusRequest(currentRequest?.id!, 'REJECTED')}>Отклонить
                        </div>
                        : ""}
                </div>

            </div>
        </div>
    );
}