import React, {ReactElement, useRef} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import {Accordion} from "../../../component/Accordion";
import {RequestBlockType} from "../../../types/RequestBlockType";
import {REST} from "../../../REST";
import {Utils} from "../../../utils/Utils";

export function RequestBlock(props: any): ReactElement {
    const block = props.block as RequestBlockType;
    let isNew: boolean = block === null || block.id === undefined;
    const requestNumber = props.requestNumber as number;
    const readOnly: boolean = props.readOnly ?? false;
    const [isSaved, setIsSaved] = props.saved as [boolean, React.Dispatch<React.SetStateAction<boolean>>];
    const [blocks, setBlocks] = props.blocks as [RequestBlockType[], React.Dispatch<React.SetStateAction<RequestBlockType[]>>];

    const nowDate = new Date();
    const now = nowDate.getFullYear().toString().padStart(2, '0') +
        "-" + (nowDate.getMonth() + 1).toString().padStart(2, '0') +
        "-" + nowDate.getDate().toString().padStart(2, '0') +
        "T" + nowDate.getHours().toString().padStart(2, '0') +
        ":" + nowDate.getMinutes().toString().padStart(2, '0');

    const start_at = useRef<HTMLInputElement>(null);
    const finish_at = useRef<HTMLInputElement>(null);
    const violations = useRef<HTMLTextAreaElement>(null);
    const result = useRef<HTMLSelectElement>(null);
    const prohibited_objects = useRef<HTMLSelectElement>(null);
    const photo_ts = useRef<HTMLInputElement>(null);
    const photo_holds = useRef<HTMLInputElement>(null);
    const cargo = useRef<HTMLInputElement>(null);
    const photos = useRef<HTMLInputElement>(null);
    const ship_role = useRef<HTMLInputElement>(null);
    const lading_bill = useRef<HTMLInputElement>(null);
    const manifesto = useRef<HTMLInputElement>(null);
    const inspection_report = useRef<HTMLInputElement>(null);
    const sealing_act = useRef<HTMLInputElement>(null);


    const save = () => {
        let formData = new FormData();

        formData.append("rid", block.rid.toString());
        formData.append("type", block.type.toString());
        if (start_at.current?.value)
            formData.append("start_at", start_at.current?.value);
        if (finish_at.current?.value)
            formData.append("finish_at", finish_at.current?.value);
        if (violations.current?.value)
            formData.append("violations", violations.current?.value);
        if (result.current?.value)
            formData.append("result", result.current?.value);
        if (prohibited_objects.current?.value)
            formData.append("prohibited_objects", prohibited_objects.current?.value);
        if (cargo.current?.value)
            formData.append("cargo", cargo.current?.value);

        let photo_ts_files: FileList | null = photo_ts.current?.files ?? null;
        if (photo_ts_files)
            for (let i = 0; i < photo_ts_files.length; i++) {
                formData.append("photo_ts", photo_ts_files.item(i)!);
            }
        let photo_holds_files: FileList | null = photo_holds.current?.files ?? null;
        if (photo_holds_files)
            for (let i = 0; i < photo_holds_files.length; i++) {
                formData.append("photo_holds", photo_holds_files.item(i)!);
            }
        let photos_files: FileList | null = photos.current?.files ?? null;
        if (photos_files)
            for (let i = 0; i < photos_files.length; i++) {
                formData.append("photos", photos_files.item(i)!);
            }
        let ship_role_files: FileList | null = ship_role.current?.files ?? null;
        if (ship_role_files)
            for (let i = 0; i < ship_role_files.length; i++) {
                formData.append("ship_role", ship_role_files.item(i)!);
            }
        let lading_bill_files: FileList | null = lading_bill.current?.files ?? null;
        if (lading_bill_files)
            for (let i = 0; i < lading_bill_files.length; i++) {
                formData.append("lading_bill", lading_bill_files.item(i)!);
            }
        let manifesto_files: FileList | null = manifesto.current?.files ?? null;
        if (manifesto_files)
            for (let i = 0; i < manifesto_files.length; i++) {
                formData.append("manifesto", manifesto_files.item(i)!);
            }
        let inspection_report_files: FileList | null = inspection_report.current?.files ?? null;
        if (inspection_report_files)
            for (let i = 0; i < inspection_report_files.length; i++) {
                formData.append("inspection_report", inspection_report_files.item(i)!);
            }
        let sealing_act_files: FileList | null = sealing_act.current?.files ?? null;
        if (sealing_act_files)
            for (let i = 0; i < sealing_act_files.length; i++) {
                formData.append("sealing_act", sealing_act_files.item(i)!);
            }

        REST.newRequestBlock(formData).then((rb: RequestBlockType) => {
            //console.log(rb);
            setBlocks((prev) => [...prev, rb]);
            setIsSaved(true);
            //setIsNew(null);
        })
    };

    switch (block.type) {
        case 1:
            return Utils.hasRole("water_ptb_request") ? (
                <Accordion showBody={isNew}
                           title={<><b>Отчет о начале досмотра</b>{isNew ? "" : (" от " + block.formattedCreatedAt)}
                               {isNew ? "" : <FontAwesomeIcon
                                   icon={faDownload} style={{paddingLeft: "5px"}}/>}</>}>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Дата начала осмотра</span>
                        {!readOnly && isNew
                            ? <input type="datetime-local" className="form-control" defaultValue={now} ref={start_at}/>
                            : <div className="form-control">{block.formattedStartAt}</div>
                        }
                        <span className="input-group-text">Дата окончания осмотра</span>
                        <div className="form-control" style={{cursor: "not-allowed"}}>не применимо</div>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Результат досмотра грузовых отсеков (трюмов) ТС</span>
                        {!readOnly && isNew
                            ? <select className="form-control" ref={result}>
                                <option selected={true}>досмотрено</option>
                                <option>не досмотрено</option>
                            </select>
                            : <div className="form-control">{block.result ?? ''}</div>
                        }
                        <span className="input-group-text">запрещенные объекты</span>
                        {!readOnly && isNew
                            ? <select className="form-control" ref={prohibited_objects}>
                                <option selected={true}>не обнаружены</option>
                                <option>обнаружены</option>
                            </select>
                            : <div className="form-control">{block.prohibited_objects}</div>
                        }
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Описание выявленных нарушений</span>
                        <textarea className="form-control" ref={violations} readOnly={readOnly}
                                  defaultValue={block.violations ?? 'не обнаружено'}></textarea>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Фото ТС</span>
                        {!readOnly && isNew
                            ? <input type={"file"} multiple={true} className="form-control" name="photo_ts"
                                     ref={photo_ts}/>
                            : <div className="form-control">{block.photo_ts?.map(p => <><a href={"#"}
                                                                                           onClick={() => REST.download(p)}>{p.name}</a>&nbsp;</>)}</div>
                        }
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Фото трюмов</span>
                        {!readOnly && isNew
                            ? <input type={"file"} multiple={true} className="form-control" name="photo_holds"
                                     ref={photo_holds}/>
                            : <div className="form-control">{block.photo_holds?.map(p => <><a href={"#"}
                                                                                              onClick={() => REST.download(p)}>{p.name}</a>&nbsp;</>)}</div>
                        }
                    </div>
                    {isNew ? <div className={"Button Primary"} onClick={() => save()}>Сохранить и подписать</div> : ""}
                </Accordion>
            )
                : <></>
        case 2:
            return (<></>);
        /*return (
            <Accordion showBody={isNew}
                       title={<><b>Отчет о преддосмотре</b>{isNew ? "" : (" от " + block.formattedCreatedAt)}
                           {isNew ? "" : <FontAwesomeIcon
                               icon={faDownload} style={{paddingLeft: "5px"}}/>}</>}>
                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">Дата начала преддосмотра</span>
                    {!readOnly && isNew
                        ? <input type="datetime-local" className="form-control" defaultValue={now} ref={start_at}/>
                        : <div className="form-control">{block.formattedStartAt}</div>
                    }
                    <span className="input-group-text">Дата окончания преддосмотра</span>
                    <div className="form-control" style={{cursor: "not-allowed"}}>не применимо</div>
                </div>
                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">Проверка документов и согласование с капитаном судна</span>
                    <div className="form-control">????????</div>
                </div>

                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">ФИО участвующих работников</span>
                    <textarea className="form-control" ref={participants} readOnly={readOnly}
                              defaultValue={block.participants ?? ''}></textarea>
                </div>
                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">Описание предварительных действий</span>
                    <textarea className="form-control" ref={actions} readOnly={readOnly} defaultValue={block.actions ?? ''}></textarea>
                </div>
                {isNew ? <div className={"Button Primary"} onClick={() => save()}>Сохранить и подписать</div> : ""}
            </Accordion>
        )*/
        case 3:
            return Utils.hasRole("water_ptb_request") ? (
                <Accordion showBody={isNew}
                           title={<><b>Отчет о досмотре</b>{isNew ? "" : (" от " + block.formattedCreatedAt)}
                               {isNew ? "" : <FontAwesomeIcon
                                   icon={faDownload} style={{paddingLeft: "5px"}}/>}</>}>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Дата начала досмотра</span>
                        {!readOnly && isNew
                            ? <input type="datetime-local" className="form-control" defaultValue={now} ref={start_at}/>
                            : <div className="form-control">{block.formattedStartAt}</div>
                        }
                        <span className="input-group-text">Дата окончания досмотра</span>
                        <div className="form-control" style={{cursor: "not-allowed"}}>не применимо</div>
                    </div>

                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Количество досмотренного груза (т/м<sup>3</sup>)</span>
                        {!readOnly && isNew
                            ? <input type="number" className="form-control" defaultValue={0} ref={cargo} min={0}/>
                            : <div className="form-control">{block.cargo}</div>
                        }
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Описание выявленных нарушений</span>
                        <textarea className="form-control" ref={violations} readOnly={readOnly}
                                  defaultValue={block.violations ?? 'запрещенных предметов не обнаружено'}></textarea>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Фотоматериалы</span>
                        {!readOnly && isNew
                            ? <input type={"file"} multiple={true} className="form-control" ref={photos}/>
                            : <div className="form-control">{block.photos?.map(p => <><a href={"#"}
                                                                                         onClick={() => REST.download(p)}>{p.name}</a>&nbsp;</>)}</div>
                        }
                    </div>
                    {isNew ? <div className={"Button Primary"} onClick={() => save()}>Сохранить и подписать</div> : ""}
                </Accordion>
            )
                : <></>
        case 4:
            return (<></>)
        /*return (
            <Accordion showBody={isNew}
                       title={<><b>Отчет о пломбировке</b>{isNew ? "" : (" от " + block.formattedCreatedAt)}
                           {isNew ? "" : <FontAwesomeIcon
                               icon={faDownload} style={{paddingLeft: "5px"}}/>}</>}>
                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">Дата начала пломбировки</span>
                    {!readOnly && isNew
                        ? <input type="datetime-local" className="form-control" defaultValue={now} ref={start_at}/>
                        : <div className="form-control">{block.formattedStartAt}</div>
                    }
                    <span className="input-group-text">Дата окончания пломбировки</span>
                    {!readOnly && isNew
                        ? <input type="datetime-local" className="form-control" defaultValue={now} ref={finish_at}/>
                        : <div className="form-control">{block.formattedFinishAt}</div>
                    }
                </div>

                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">Дата и время установки пломб</span>
                    {!readOnly && isNew
                        ? <input type="datetime-local" className="form-control" defaultValue={now} ref={sealsDate}/>
                        : <div className="form-control">{block.formattedSealsDate}</div>
                    }
                </div>
                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">Места установки пломб</span>
                    <textarea className="form-control" ref={sealsPlaces} readOnly={readOnly}
                              defaultValue={block.seals_places ?? ''}></textarea>
                </div>
                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">Номера установленных пломб</span>
                    <textarea className="form-control" ref={sealsNumber} readOnly={readOnly}
                              defaultValue={block.seals_number ?? ''}></textarea>
                </div>

                <div className="input-group input-group-sm mb-1">
                    <span className="input-group-text">Описание процесса пломбировки</span>
                    <textarea className="form-control" ref={actions} readOnly={readOnly} defaultValue={block.actions ?? ''}></textarea>
                </div>
                {isNew ? <div className={"Button Primary"} onClick={() => save()}>Сохранить и подписать</div> : ""}
            </Accordion>
        )*/
        case 5:
            return (
                <Accordion showBody={isNew}
                           title={<><b>Отчет о результатах
                               досмотра</b>{isNew ? "" : (" от " + block.formattedCreatedAt)}{isNew ? "" :
                               <FontAwesomeIcon
                                   icon={faDownload} style={{paddingLeft: "5px"}}/>}</>}>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Дата начала досмотра</span>
                        <div className="form-control" style={{cursor: "not-allowed"}}>не применимо</div>
                        <span className="input-group-text">Дата окончания досмотра</span>
                        {!readOnly && isNew
                            ? <input type="datetime-local" className="form-control" defaultValue={now} ref={finish_at}/>
                            : <div className="form-control">{block.formattedFinishAt}</div>
                        }
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Описание выявленных нарушений и принятых мер</span>
                        <textarea className="form-control" ref={violations} readOnly={readOnly}
                                  defaultValue={block.violations ?? 'не обнаружено'}></textarea>
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Код акта досмотра</span>
                        <div className="form-control text-center fw-bold">{String(requestNumber).padStart(5, '0')[0]}</div>
                        <div className="form-control text-center fw-bold">{String(requestNumber).padStart(5, '0')[1]}</div>
                        <div className="form-control text-center fw-bold">{String(requestNumber).padStart(5, '0')[2]}</div>
                        <div className="form-control text-center fw-bold">{String(requestNumber).padStart(5, '0')[3]}</div>
                        <div className="form-control text-center fw-bold">{String(requestNumber).padStart(5, '0')[4]}</div>
                        <span className="input-group-text fw-bold">&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <div className="form-control text-center fw-bold">{new Date().getFullYear().toString()[2]}</div>
                        <div className="form-control text-center fw-bold">{new Date().getFullYear().toString()[3]}</div>
                        <span className="input-group-text fw-bold">&nbsp;&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        {
                            //TODO: Сгенерированный код акта досмотра
                        }
                        <div className="form-control text-center fw-bold">9</div>
                        <div className="form-control text-center fw-bold">9</div>
                        <div className="form-control text-center fw-bold">9</div>
                    </div>
                    {Utils.hasRole("water_ptb_request")
                        ? <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Судовая роль</span>
                            {!readOnly && isNew
                                ? <input type={"file"} className="form-control" ref={ship_role}/>
                                : <div className="form-control">{block.ship_role?.map(p => <><a href={"#"}
                                                                                                onClick={() => REST.download(p)}>{p.name}</a>&nbsp;</>)}</div>
                            }
                        </div>
                        : ""
                    }
                    {Utils.hasRole("water_ptb_request")
                        ? <div className="input-group input-group-sm mb-1">
                            <span className="input-group-text">Коносамент</span>
                            {!readOnly && isNew
                                ? <input type={"file"} className="form-control" ref={lading_bill}/>
                                : <div className="form-control">{block.lading_bill?.map(p => <><a href={"#"}
                                                                                                  onClick={() => REST.download(p)}>{p.name}</a>&nbsp;</>)}</div>
                            }
                        </div>
                        : ""
                    }
                    {/*<div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Манифест</span>
                        {!readOnly && isNew
                            ? <input type={"file"} className="form-control" ref={manifesto}/>
                            : <div className="form-control">{block.manifesto?.map(p => <><a href={"#"}
                                                                                            onClick={() => REST.download(p)}>{p.name}</a>&nbsp;</>)}</div>
                        }
                    </div>
                */}
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Акт досмотра</span>
                        {!readOnly && isNew
                            ? <input type={"file"} multiple={true} className="form-control" ref={inspection_report}/>
                            : <div className="form-control">{block.inspection_report?.map(p => <><a href={"#"}
                                                                                                    onClick={() => REST.download(p)}>{p.name}</a>&nbsp;</>)}</div>
                        }
                    </div>
                    <div className="input-group input-group-sm mb-1">
                        <span className="input-group-text">Акт пломбировки / распломбировки</span>
                        {!readOnly && isNew
                            ? <input type={"file"} multiple={true} className="form-control" ref={sealing_act}/>
                            : <div className="form-control">{block.sealing_act?.map(p => <><a href={"#"}
                                                                                              onClick={() => REST.download(p)}>{p.name}</a>&nbsp;</>)}</div>
                        }
                    </div>
                    {isNew ? <div className={"Button Primary"} onClick={() => save()}>Сохранить и подписать</div> : ""}
                </Accordion>
            )
        default:
            return (<Accordion showBody={isNew}
                               title={<><b style={{color: "red"}}>Неизвестный тип блока</b></>}></Accordion>);
    }
}